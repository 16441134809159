import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import PageHeader from "./PageHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  textRight: {
    float: "right",
  },
  gridcontainpart: {
    //marginTop: "1.5rem",
  },
  heading: {
    marginBottom: "1rem",
  },
}));
export default function Report() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PageHeader title="Reports" />
      <Grid container spacing={3} className={classes.gridcontainpart}>
        <Grid item xs={12} sm={3} md={3}>
          <div className="r-card r-card-1">
            <p className="r-card-title">123</p>
            <p className="r-card-label">Tenants</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <div className="r-card r-card-2">
            <p className="r-card-title">₹3,53,456</p>
            <p className="r-card-label">Spend</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <div className="r-card r-card-3">
            <p className="r-card-title">₹4,565</p>
            <p className="r-card-label">Earned</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <div className="r-card r-card-4">
            <p className="r-card-title">₹5,345</p>
            <p className="r-card-label">Balance</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
