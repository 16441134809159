import { action, computed, persist } from "easy-peasy";

const metaModel = persist({
  data: null,
  isFound: computed((state) => state.data != null),
  save: action((state, payload) => {
    state.data = payload;
  }),
});
export default metaModel;
