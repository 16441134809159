import React from 'react'
import { AppBar, makeStyles, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ownnten from "../../assets/OwnntenIcon.png";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    largeScreen: {
      width :"5%",
      padding: "1rem",
      border: "none",
    },
    arrowback: {
      color: "#772360",
      "&:hover": {
        color: "#000",
      },
    }, 
    logo:{
      height:"2.7em",
    } ,  
    title: {
      fontSize: "1rem",
      paddingLeft: "0.5rem",
    },
  }));
function AddListAppSignup(props) {
    const classes = useStyles();
  return (
    <div>
      <AppBar
        position="sticky"
        style={{
          backgroundColor: "transparent",
          // boxShadow: "0 1px 9px 1px rgba(0, 0, 0, 0.05)",
        }}
        className={classes.largeScreen}
      >
        <div
          style={{ display: "flex", cursor: "pointer", flexDirection: "row" }}
          onClick={() => props.onClose()}
        >
          <Link to="/" style={{ textDecoration: "none" }}>
           <img src={ownnten} alt="logo" className={classes.logo} />
           </Link>
          {/* <ArrowBackIcon className={classes.arrowback}></ArrowBackIcon>
          <Typography variant="h6" className={classes.title} color="primary">
            Back
          </Typography> */}
        </div>
      </AppBar>
    </div>
  )
}

export default AddListAppSignup