import { action, computed } from "easy-peasy";

const sessionModel = {
  user: null,
  isLoggedIn: computed((state) => state.user != null),
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  unsetUser: action((state, payload) => {
    state.user = null;
  }),
};
export default sessionModel;
