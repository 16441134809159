import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as yup from "yup";
import { UserContext, UserDispatchContext } from "../../context/UserContext";
import PageHeader from "./PageHeader";
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  btnlay:{
    margin:"0 auto"
  },
  gridcontainpart:{
    marginTop:"1.5rem"
  },
  form: {
    margin: "1rem 0 1rem",
  },
}));

const schema = yup.object().shape({
  userName: yup
    .string("Enter your name")
    .typeError("Name name must be a alphbet")
    .required("Name is required"),
  userEmail: yup
    .string()
    .required("Email is required")
    .matches(emailRegex, "Email is not valid"),
  userMobile: yup
    .string()
    .required("Mobile number is required")
    .matches(/^[6-9]\d{9}$/, "Mobile number is not valid")
    .min(10, "Too short")
    .max(10, "Too long"),
  userPassword: yup
    .string()
    .required("Password is required")
    .min(6, "Password is too short")
    .max(12, "Password too long"),
});

export default function Profile(props) {
  const classes = useStyles();
  const userDetails = useContext(UserContext);
  const setUserDetails = useContext(UserDispatchContext);
  const [loading, setLoading] = useState(false);
  // const isEnabled = email.length > 0 && mobile.length > 0;
  //const setUser = useStoreActions((action) => action.session.setUser);

  let history = useHistory();

  const initialValues = {
    userId: userDetails.userId,
    userName: userDetails.userName,
    userEmail: userDetails.userEmail,
    userMobile: userDetails.userMobile,
  };

  const { control, handleSubmit, setValue, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });
  const onSubmit = (data) => {
  };

  return (
    <div className={classes.root}>
      <PageHeader title="Profile" />
      <Paper variant="elevation" elevation={0}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} className={classes.gridcontainpart}>
            <Grid item xs={12} sm={4} md={4}>
              <Controller
                control={control}
                name="userName"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    type="text"
                    label="Full name"
                    className={classes.inputfiled}
                    fullWidth
                    placeholder="Enter your name"
                    color="primary"
                    autoComplete="off"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Controller
                control={control}
                name="userMobile"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    type="text"
                    label="Mobile Number"
                    className={classes.inputfiled}
                    fullWidth
                    placeholder="Enter your mobile number"
                    color="primary"
                    autoComplete="off"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Controller
                control={control}
                name="userEmail"
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    type="text"
                    label="Email"
                    className={classes.inputfiled}
                    fullWidth
                    placeholder="Enter your email"
                    color="primary"
                    autoComplete="off"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.btnlay}>
              <Button
                type="submit"
                className={classes.btnlogin}
                variant="outlined"
                color="primary"
                disabled={loading}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
  
      </Paper>
    </div>
  );
}
