import { action, computed } from "easy-peasy";

const myPropertyModel = {
  item: [],
  //isFound: computed((state) => state.item.length != 0),
  rented: computed((state) =>
    state.item.filter((prop) => prop.agreementStatus)
  ),
  available: computed((state) =>
    state.item.filter((prop) => (prop.agreementStatus == null) || (prop.agreementStatus == 0))
  ),
  save: action((state, payload) => {
    state.item = payload;
  }),
  add: action((state, payload) => {
    state.item.push(payload);
  }),
  updateById: action((state, payload) => {
    let i = state.item.findIndex(
      (item) => item.propertyId === payload.propertyId
    );
    state.item[i].tenantId = payload.tenantId;
    state.item[i].tenantStatus = "CONFIRMED";
  }),
  setPropertyloading: action((state, payload) => {
    state.propertyloading = payload;
  }),
  addDocument: action((state, payload) => {
    let i = state.item.findIndex(
      (item) => item.propertyId === payload.propertyId
    );
    state.item[i].documents = payload.documents;
  }),
  deleteById: action((state, payload) => {
    state.item.splice(
      state.item.findIndex((item) => item.propertyId == payload),
      1
    );
  }),
};
export default myPropertyModel;
