import {
  Button,
  Divider,
  Link,
  Slide,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AssessmentIcon from "@material-ui/icons/Assessment";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PeopleIcon from "@material-ui/icons/People";
import React, { useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
import Profile from "./Profile";
import Report from "./Report";
import Wallet from "./Wallet";
const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  listBox: {
    display: "flex",
  },
  appBar: {
    color: "white",
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    boxShadow: "0 1px 9px 1px rgba(0, 0, 0, 0.05)",
    justifyContent: "space-between",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.08), 0 0 5px rgba(0, 0, 0, 0.05)",
    border: 0,
  },
  drawerContainer: {
    overflow: "auto",
    padding: "2px 6px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#77236003",
    height: "100vh",
  },
  padding: {
    padding: theme.spacing(1),
  },
  refercode: {
    padding: theme.spacing(1),
    fontSize: "1rem",
    fontWeight: "500",
  },
  rightSection: {
    display: "flex",
    alignItems: "baseline",
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
    justifyContent: "space-between",
  },
  tabhead: {
    fontSize: "0.9rem",
    fontWeight: "500",
  },
  btn: {
    color: "white",
    outline: "1px solid white",
  },
  menuTxt: {
    color: "#37414a",
  },
  taboutline: {
    padding: "0.5rem",
    border: "2px solid #fff",
    "&:hover": {
      backgroundColor: "#f7f7f7",
    },
  },
}));
function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function FAHome() {
  const classes = useStyles();
  const [menuIndex, setMenuIndex] = useState(0);
  const [toast, setToast] = React.useState({
    show: false,
    msg: null,
  });
  const [transition, setTransition] = React.useState(undefined);
  const userDetails = useContext(UserContext);

  const handleToastClick = (Transition) => () => {
    //setTransition(() => Transition);
    setToast({
      show: true,
      msg: "Referral code copied!",
    });
  };
  const handleToastClose = () => {
    setToast({
      show: false,
      msg: null,
    });
  };

  const getContent = (i) => {
    switch (i) {
      case 0:
        return <Report />;
      case 1:
        return <Wallet />;
      case 2:
        return <Profile />;
      default:
        return <p>Error: Invalid</p>;
    }
  };

  const copyReferralCode = (text) => {
    navigator.clipboard.writeText(text);
    // handleToastClick(TransitionUp);
    setToast({
      show: true,
      msg: "Referral code copied!",
    });
  };

  return (
    <div className={classes.listBox}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <Typography variant="h6" noWrap>
              OwnnTen
            </Typography>
          </Link>
          <div>
            {userDetails && userDetails.userMobile ? (
              <Link
                to="/login"
                style={{ textDecoration: "none", color: "white" }}
              >
                <Button variant="outlined" color="text" className={classes.btn}>
                  Logout
                </Button>
              </Link>
            ) : (
              <Link
                to="/login"
                style={{ textDecoration: "none", color: "white" }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.btn}
                >
                  Login
                </Button>
              </Link>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        elevation={20}
        PaperProps={{ variant: "elevation" }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <div className={classes.root}>
            <List>
              {["Dashboard", "Wallet", "Settings"].map((text, index) => (
                <ListItem
                  selected={menuIndex === index}
                  className={classes.taboutline}
                  button
                  key={text}
                  onClick={() => setMenuIndex(index)}
                >
                  <ListItemIcon>
                    {index === 0 ? <PeopleIcon /> : <AssessmentIcon />}
                  </ListItemIcon>
                  <ListItemText className={classes.menuTxt} primary={text} />
                </ListItem>
              ))}
            </List>
          </div>

          <div className={classes.refercode}>Referral Code</div>
          <Divider />
          <List>
            <Tooltip title="Copy code" placement="top" arrow>
              <ListItem button onClick={() => copyReferralCode("FA-001")}>
                <ListItemIcon>
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText primary={"FA-001"} />
              </ListItem>
            </Tooltip>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {getContent(menuIndex)}
      </main>
      <Snackbar
        open={toast.show}
        onClose={handleToastClose}
        // TransitionComponent={transition}
        message={toast.msg}
        key={transition ? transition.name : ""}
      />
    </div>
  );
}
