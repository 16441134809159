import React from "react";
import AddListAppBar from "../components/AppBar/AddListAppBar";
import AddOrEditPropertyForm from "../components/Form/AddOrEditPropertyForm";
import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    containerSection: {
      background: "#fff",
      padding: "1.5rem 1.5rem",
    },
  })
);

export default function EditProperty() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <AddListAppBar onClose={() => history.goBack()} />
      <Grid alignItems="stretch" container xs={12} sm={12}>
        <Grid item xs={12} sm={12} md={4} className={classes.containerSection}>
          <AddOrEditPropertyForm onLatLongFound={() => {}} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.containerSection}>
          world
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.containerSection}>
          world
        </Grid>
      </Grid>
    </>
  );
}

EditProperty.propTypes = {};
