import { AppBar, makeStyles, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React from "react";

const useStyles = makeStyles((theme) => ({
  largeScreen: {
    width :"5%",
    padding: "1rem",
    border: "none",
  },
  arrowback: {
    color: "#772360",
    "&:hover": {
      color: "#000",
    },
  },
  backb:
  {
    borderBottom: '2px solid rgba(0, 0, 0, 0.05)',

  },
  title: {
    fontSize: "1rem",
    paddingLeft: "0.5rem",
  },
}));
function AddListAppBar(props) {
  const classes = useStyles();
  return (
    <div>
      <AppBar
        position="sticky"
        style={{
          backgroundColor: "#ffffff",
          // boxShadow: "0 1px 9px 1px rgba(0, 0, 0, 0.05)",
        }}
        className={classes.largeScreen}
      >
        <div
          style={{ display: "flex", cursor: "pointer", flexDirection: "row" }}
          onClick={() => props.onClose()}
        >
          <ArrowBackIcon className={classes.arrowback}></ArrowBackIcon>
          <Typography variant="h6" className={classes.title} color="primary">
            Back
          </Typography>
        </div>
      </AppBar>
    </div>
  );
}

export default AddListAppBar;
