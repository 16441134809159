import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import image1 from "../assets/loginimage.png";
import AddListAppSignup from "../components/AppBar/AddListAppSignup";
import API from "../helper/rest";
import { UserContext, UserDispatchContext } from "../context/UserContext";
import RegexTextField from "./RegexTextField";
import { useStoreActions, useStoreState } from "easy-peasy";
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = makeStyles((theme) => ({
  MuiInputBase: {},
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(4, 0),
    },

    "& .MuiFormLabel-root.Mui-focused": {
      color: "lightgray",
    },
    "& .MuiInputBase-input": {
      fontSize: "1.2rem",
      paddingTop: "1rem",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "2px solid #772360",
    },
  },
  roothead: {
    display: "flex",
    width: "100%",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  righthead: {
    fontFamily: "Poppins",
    fontSize: "2rem",
    fontWeight: "lighter",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      paddingTop: "2.5rem",
    },
  },
  righthead1: {
    fontFamily: "Poppins",
    fontSize: "2rem",
    fontWeight: "600",
    paddingBottom: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  header: {
    fontSize: "2.8rem",
    fontWeight: "normal",
    lineHeight: "1.5",
    fontFamily: "PlayfairDisplay-Regular",
    color: "#1d0e1a",
    width: "45%",
    margin: "0 auto",
    padding: "2rem 0rem",
    textAlign: "center",
    alignItems: "center",
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem 4rem",
      fontSize: "2rem",
    },
  },
  inputfiled: {
    marginBottom: "1rem",
    borderBottom: "1px solid #ccc",
  },
  title: {
    fontSize: 13,
  },
  select: {
    "&:before": {
      borderColor: "red",
      color: "white",
    },
    "&:after": {
      borderColor: "green",
      color: "white",
    },
  },
  btnlogin: {
    background: "#772360",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: "0.8rem",
    width: "55%",
    borderRadius: "1.5rem",
    padding: "0.6rem",
    fontWeight: "bold",
    margin: "1rem auto",
    boxShadow: "none",
    "&:hover": {
      background: "#b43d93",
    },
    "&:disabled": {
      cursor: "no-drop",
      backgroundColor: "gray",
      color: "white",
    },
  },
  gridview1: {
    justifyContent: "space-around",
  },
  gridview2: {
    position: "absolute",
    bottom: 0,
    height: "28rem",
    width: "100%",
    [theme.breakpoints.only("md")]: {
      height: "28rem",
    },
  },
  leftback: {
    display: "block",
    background: "#f3ead4",
    position: "relative",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  btnlog: {
    width: "100%",
    padding: "2.5rem 0rem",
  },
  rightback: {
    background: "#fff",
    padding: "0rem 8rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem 3rem",
      background: "#f2ebdc",
    },

  },
  containlogin: {
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      // height:"auto",
      background: "#f2ebdc",
    },
  },
  msgBox: {},
  msgText: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.57",
    letterSpacing: "normal",
    color: "#333333",
    padding: "1rem",
    borderRadius: "4px",
    backgroundColor: "#fef3e8",
  },
  linttext: {
    paddingLeft: "6px",
    color: theme.palette.primary.main,
    fontSize: "0.87rem",
    fontWeight: "bold",
    textDecoration: "none !important",
  },
  createlabel: {
    fontSize: "0.87rem",
    color: "#666666",
    marginTop: "0.5rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function Login(props) {
  const classes = useStyles();
  const [mobile, setMobile] = useState("");
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [error, setError] = useState(null);
  const onlyAlphaRegex = /[^a-z ]/gi;
  const onlynumericRegex = /[^0-9]/gi;
  const [loading, setLoading] = useState(false);
  const setUserDetails = useContext(UserDispatchContext);
  const setMobileNumber = useStoreActions((action) => action.login.setMobile);
  const mobileNumber = useStoreState((state) => state.login.mobile);
  // const [mobileNumber, setMobileNumber] = useState("");
  let history = useHistory();
  function validateMobile(number) {
    return /^[6-9]\d{9}$/.test(number);
  }
  const handleMobileChange = (number) => {
    if (!validateMobile(number)) {
      setError({ type: "MOBILE" });
      setIsValidNumber(false);
    } else {
      setError(null);
      setIsValidNumber(true);
    }
    setMobile(number);
  };
  const sendSMS = () => {
    setLoading(true);
    if (mobileNumber.length == 10) {
      API.post("/users/sendOTP", {
        userMobile: mobileNumber,
      })
        .then((response) => {
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const onSubmit = () => {
    setLoading(true);
    if (mobile.length == 10) {
      history.replace({ pathname: "/LoginPsw", state: { direct: props.direct } });
      API.post("/users/isUserExist", {
        userMobile: mobileNumber,
      })
        .then((response) => {
          if (response.data.userMobile == mobileNumber) {
            sendSMS();
            history.replace({ pathname: "/LoginPsw", state: { direct: props.direct } });
          } else {
            history.replace({ pathname: "/register" });
          }
        })
        .catch((err) => {
        })
        .finally((err) => {
          setLoading(false);
        });
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmit();
      event.preventDefault();
    }
  };

  return (
    <>
      <Grid
        container
        xs={12}
        sm
        justify="center"
        alignItems="center"
        displa="flex"
        className={classes.containlogin}
      >
        <Grid item xs={12} sm={6} md={6} className={classes.leftback}>
          <AddListAppSignup onClose={() => history.replace({ pathname: "/" })} />
          <Grid item xs={12} sm={12} md={12} className={classes.gridview1}>
            <Typography variant="h6" className={classes.header}>
              Door step to Happiness!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={classes.gridview2}>
            <img src={image1} alt="backgroundimage" className={classes.image} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={classes.rightback}>
          <div className={classes.roothead}>
            <Typography className={classes.righthead}>Hey there!</Typography>
          </div>
          <Typography className={classes.righthead1}>Welcome back. </Typography>
          {/* {error && <div className={classes.msgText}>{error.msg}</div>} */}
          <form>
            <RegexTextField
              type="text"
              onKeyDown={handleKeyDown}
              regex={onlynumericRegex}
              inputProps={{ maxLength: 10 }}
              className={classes.inputfiled}
              fullWidth
              label="Mobile Number"
              color="primary"
              autoComplete="off"
              // value={value}
              variant="standard"
              // onChange={onChange}
              onChange={(e) => {
                handleMobileChange(e.target.value);
              }}
              onInput={(e) => setMobileNumber(e.target.value)}
            />
            <center> <Button
              // type="submit"
              // !(mobile.length == 10)
              disabled={!isValidNumber}
              className={classes.btnlogin}
              variant="contained"
              color="primary"
              onClick={(e) => onSubmit(e)}
            // onClick={() => history.replace({ pathname: "/LoginPsw",state:{number: mobileNumber} })}
            >
              Continue
            </Button></center>
          </form>
          <center>
            <Typography className={classes.createlabel}>
              Dont have an account?{" "}
              <Link to="/register" className={classes.linttext}>
                {" "}
                Create Account
              </Link>
            </Typography>
          </center>
          {/* <center>
              <Link to="/ForgotPassword">
            <Typography className={classes.createlabel}>Forgot Password?{" "}</Typography>
              </Link>
          </center>           */}
        </Grid>
      </Grid>
    </>
  );
}