import {
  Chip,
  createTheme,
  Grid,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex",
    fontFamily: "Poppins",
    fontSize: "0.8rem",
    flexWrap: "wrap",
    color: "#333333",
    "& > *": {
      margin: theme.spacing(0.3, 0.4),
    },
    "& .MuiChip-colorPrimary": {
      background: "#Fff",
      color: "#3fb575",
      border: "1px solid #3fb575",
    },
    "& .makeStyles-listChip": {
      margin: 0,
    },
  },
  chip: {
    fontFamily: "Poppins",
    marginTop: "0.3rem",
    marginBottom: "0.3rem",
  },
}));
const theme = createTheme({
  overrides: {
    MuiChip: {
      root: {
        color: "#333333",
        border: "1px dashed #cccccc",
        backgroundColor: "#fff",
        padding: "0.3rem",
        margin: "0.5rem",
        fontSize: "11px",
        "&:focus": {
          background: "#fff",
        },
        "&:active": {
          color: "#3fb575",
          border: "1px solid #3fb575",
          backgroundColor: "#fff",
        },
        "& .MuiChip-colorPrimary": {
          background: "#Fff",
          color: "#3fb575",
          border: "1px solid #3fb575",
        },
      },
      outlined: {
        color: "#3fb575",
        border: "1px solid #3fb575",
        boxShadow: "0px 0.4px 4px 0px #3fb575",
        backgroundColor: "#fff",
        "$clickable&:hover, $clickable&:focus, $deletable&:focus": {
          backgroundColor: "#fff",
        },
      },
    },
  },
});
export default function StyledChipGroupObj(props) {
  const classes = useStyles();
  const [selected, setSelected] = useState(props.default || null);
  const handleChange = (option) => {
    setSelected(option);
    props.onSelect(props.name, option);
  };
  useEffect(() => {
    setSelected(props.value);
  }, [props.value]);
  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.root}>
            {props.options &&
              props.options.map((option, i) => {
                return (
                  <Chip
                    key={i}
                    className={classes.chip}
                    label={option.value}
                    name={props.name}
                    onClick={(e) => handleChange(option)}
                    variant={
                      selected && selected.id === option.id
                        ? "outlined"
                        : "default"
                    }
                  />
                );
              })}
          </div>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
