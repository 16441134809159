import { lazy } from "@loadable/component";
import { MuiThemeProvider } from "@material-ui/core";
import { useStoreActions } from "easy-peasy";
import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import ThreeDots from "./components/Loader/ThreeDots";
import PrivateRoute from "./components/PrivateRoute";
import EditProperty from "./pages/EditProperty";
import FAHome from "./pages/FinancialAdmin/FAHome";
import theme from "./theme";

const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const AllRentAvailableTab = lazy(() => import ( "./components/Profile/AllRentAvailableTab"));
const AccountView = lazy(()=> import("./components/Profile/AccountView"));
const AccountView1 = lazy(()=> import("./components/Profile/AccountView1"));
const AddList = lazy(() => import("./pages/AddList"));
const Landingpage = lazy(() => import("./pages/Landingpage"));
const MapView = lazy(() => import("./pages/MapView"));
const PropertyListPage = lazy(() => import("./pages/PropertyListPage"));
const PropertyDetails = lazy(() => import("./pages/PropertyDetails"));
const EditablePage = lazy(() => import("./pages/EditablePage"));
const MyProperties = lazy(() => import("./pages/MyProperties"));
const AddKyc = lazy(() => import("./components/Dialog/AddKyc"));
const MyProfile = lazy(() => import("./pages/MyProfile"));
const LoginOTP = lazy(() => import("./pages/LoginOTP"));
const LoginPsw = lazy(() => import("./pages/LoginPsw"));
const VerticalTab = lazy(() => import("./pages/VerticalTab"));
const LoanT = lazy(() => import("./pages/LoanT"));
const AddNewTenant = lazy(() => import("./pages/AddNewTenantStep"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const RefundPolicy = lazy(() => import("./pages/RefundPolicy"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const Filter1 = lazy(() => import("./components/Filter1"));
const PayRent = lazy(() => import("./pages/PayRent"));
const AboutUs = lazy(() => import("./pages/AboutUs"));

// const Preview = lazy(() => import("./pages/Preview"));
export default function Root(props) {
  // const setMetadata = useStoreActions((actions) => actions.meta.save);
  const setUser = useStoreActions((actions) => actions.session.setUser);
  // const meta = useStoreState((state) => state.meta.data);
  useEffect(() => {
    let userMobile = localStorage.getItem("user");
    //let loc = null;
    // localStorage.setItem("location", null);
    let loc = JSON.parse(localStorage.getItem("location"));
    setUser({
      mobile: userMobile == null || userMobile == "null" ? null : userMobile,
      location: loc,
    });
  }, []);

  // const Loading = () => {
  //   return (
  //     <div class="loadingio-spinner-ripple-7sym28a8hzt">
  //       <div class="ldio-20yaf67vp4d">
  //         <div></div>
  //         <div></div>
  //       </div>
  //     </div>
  //   );
  // };
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <Suspense fallback={<ThreeDots />}>
          {/* <Suspense
          fallback={
            <center>
              <CircularProgress />
            </center>
          }
        > */}
          <Route path="/" exact component={Landingpage} />
          <Route path="/search" component={PropertyListPage} />
          <Route path="/map" component={MapView} />
          <Route path="/edit-property" component={EditProperty} />
          <Route path="/edit-page" component={EditablePage} />
          <Route path="/detail" component={PropertyDetails} />
          <Route path="/ResetPassword" component={ResetPassword} />
          <Route path="/ForgotPassword" component={ForgotPassword} />
          <Route path="/LoginOTP" component={LoginOTP} />
          <Route path="/LoginPsw" component={LoginPsw} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <PrivateRoute component={AddList} path="/add-property" />
          {/* <Route path="/add-property" component={AddList} /> */}
          <PrivateRoute path="/pay-rent" component={PayRent} />
          <PrivateRoute path="/my-property" component={MyProperties} />
          <PrivateRoute path="/AddKyc" component={AddKyc} />
          <PrivateRoute path="/MyProfile" component={MyProfile} />
          <PrivateRoute path="/AccountView1" component={AccountView1} />
          <PrivateRoute path="/AccountView" component={AccountView} />
          <PrivateRoute path="/AllRentAvailableTab" component={AllRentAvailableTab} />
          <PrivateRoute path="/my-profile" component={VerticalTab} />
          <PrivateRoute path="/loan-transaction" component={LoanT} />
          <PrivateRoute path="/add-tenant/:id" component={AddNewTenant} />
          <PrivateRoute path="/financial-admin" component={FAHome} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/refund-policy" component={RefundPolicy} />
          <Route path="/Filter1" component={Filter1} />

          {/* <PrivateRoute path="/Preview" component={Preview} /> */}
          <Route path="/about-us" component={AboutUs} />
          <Route
            path="/terms-and-conditions"
            component={TermsConditions}
          />
        </Suspense>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}
