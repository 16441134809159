import { action } from "easy-peasy";

const profileModel = {
  banks: [],
  documents: [],
  accounts: [],
  ownerInvoice: [],
  ownerInvoices: [],
  tenantInvoice: {
    data: [],
    lastUpdate: null,
  },
  loans: [],
  debittransactions:[],
  credittransactions:[],
  myTenants: {
    data: [],
    lastUpdate: null,
  },
  reset: action((state, payload) => {
    state.banks = [];
    state.documents = [];
    state.accounts = [];
    state.ownerInvoice = [];
    state.ownerInvoices = [];
    state.tenantInvoice = [];
  }),
  saveLoans: action((state, payload) => {
    state.loans = payload.length === 0 ? [] : payload;
  }),
  savedebitTransaction: action((state, payload) => {
    state.debittransaction = payload.length === 0 ? [] : payload;
  }),
  savedebitTransactions: action((state, payload) => {
    state.debittransactions = payload;
  }),
  savecreditTransaction: action((state, payload) => {
    state.credittransaction = payload.length === 0 ? [] : payload;
  }),
  savecreditTransactions: action((state, payload) => {
    state.credittransactions = payload;
  }),
  saveOnwerInvoice: action((state, payload) => {
    state.ownerInvoice = payload;
  }),
  saveOnwerInvoices: action((state, payload) => {
    state.ownerInvoices = payload;
  }),
  saveMyTenants: action((state, payload) => {
    let pay = {
      data: payload,
      lastUpdate: new Date().getTime(),
    };
    state.myTenants = pay;
  }),
  saveTenantInvoice: action((state, payload) => {
    let pay = {
      data: payload,
      lastUpdate: new Date().getTime(),
    };
    state.tenantInvoice = pay;
  }),
  saveBank: action((state, payload) => {
    state.banks = payload;
  }),
  saveDocument: action((state, payload) => {
    state.documents = payload;
  }),
  saveAccount: action((state, payload) => {
    state.accounts = payload;
  }),
  addBank: action((state, payload) => {
    state.banks.push(payload);
  }),
  addLoan: action((state, payload) => {
    state.loans.push(payload);
  }),
  addDocument: action((state, payload) => {
    state.documents.push(payload);
  }),
  addAccount: action((state, payload) => {
    state.accounts.push(payload);
  }),
  deleteDocById: action((state, payload) => {
    state.item.splice(
      state.documents.findIndex((item) => item.documentId == payload),
      1
    );
  }),
  deleteAccById: action((state, payload) => {
    state.item.splice(
      state.documents.findIndex((item) => item.userAccountId == payload),
      1
    );
  }),
};
export default profileModel;
