/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
//var AWS = require("aws-sdk");
// import { AWS } from "aws-sdk";
{
  /*const s3Config = {
  bucketName: "rentalclan",
  region: "us-east-2",
  accessKeyId: "AKIAR6EKCKCPN5WLX4AA",
  secretAccessKey: "DYkqZGmXSevsChpMkX1RuFx2fjdGapYJWAxS7",
  identityPoolId: "us-east-2:14eb4ade-4eb7-4b11-95e5-c9e9950ecd77",
};*/
}
const s3Config = {
  bucketName: "owntenproperty",
  region: "ap-south-1",
  accessKeyId: "AKIAVRUVQIXVUSOBZGNP",
  secretAccessKey: "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",
  identityPoolId: "ap-south-1:2c3227ab-c943-42ae-9faf-7421d5767a81",
};

window.AWS.config.update({
  region: s3Config.region,
  credentials: new window.AWS.CognitoIdentityCredentials({
    IdentityPoolId: s3Config.identityPoolId,
  }),
});

var s3 = new window.AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: s3Config.bucketName },
});

const processResponse = (response, meta) => {
  var property = response;
  for (var i = 0; i < property.length; i++) {
    property[i].agreementDuration = meta.agreementDuration.find(
      (item) => property[i].agreementDuration == item.id
    );
    property[i].propertyAge = meta.propertyAge.find(
      (item) => property[i].propertyAge == item.id
    );
    property[i].propertyType = meta.propertyType.find(
      (item) => property[i].propertyType == item.id
    );
    property[i].propertyCategory = meta.propertyCategory.find(
      (item) => property[i].propertyCategory == item.id
    );
    property[i].propertyContactType = meta.propertyContactType.find(
      (item) => property[i].propertyContactType == item.id
    );
    property[i].bathRoom = meta.bathRoomOption.find(
      (item) => property[i].bathRoom == item.id
    );
    property[i].tenantType = meta.tenantType.find(
      (item) => property[i].tenantType == item.id
    );
    property[i].bhkType = meta.bhkType.find(
      (item) => property[i].bhkType == item.id
    );
    property[i].furnishType = meta.furnishType.find(
      (item) => property[i].furnishType == item.id
    );
    property[i].openParking = meta.parkingOption.find(
      (item) => property[i].openParking == item.id
    );
    property[i].amenity?.forEach((am, index) => {
      property[i].amenity[index] = meta.amenity.find(
        (item) => am.propertyAmenityId == item.id
      );
    });
  }
  return property;
};
const getUser = () => {
  return localStorage.getItem("user");
};
const setUser = (user) => {
  localStorage.setItem("user", user);
};

const setLocation = (location) => {
  localStorage.setItem("loc", JSON.stringify(location));
};
const getLocation = () => {
  return JSON.parse(localStorage.setItem("loc"));
};

const getImages = (images) => {
  return images.filter((image) => typeof image === "object");
};

const getAddressParts = (object) => {
  let address = {};
  const address_components = object.address_components;
  address_components.forEach((element) => {
    address[element.types[0]] = element.short_name;
  });
  return address;
};
function getShortAddress(object) {
  let address = {};
  const address_components = object.address_components;
  address_components.forEach((element) => {
    address[element.types[0]] = element.short_name;
  });
  return address;
}

function getLongAddress(object) {
  let address = {};
  const address_components = object.address_components;
  address_components.forEach((element) => {
    address[element.types[0]] = element.long_name;
  });
  return address;
}

const processInvoice = (invoice) => {};

const checkLastUpdate = (val) => {
  let time = new Date().getTime() / 1000;
  //let diff = time - val.lastUpdate / 1000;
  if (val?.lastUpdate === null || time - val?.lastUpdate / 1000 > 60) {
    return true;
  } else {
    return false;
  }
};

const groupBy = (keys) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = keys.map((key) => obj[key]).join("-");
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByPropertyId = groupBy(["propertyId"]);
const groupByInvoiceType = groupBy(["invoiceType"]);
const groupByDueDate = groupBy(["dueDate"]);

function inWords(num) {
  var ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  var tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  var numString = num.toString();

  if (num < 0) throw new Error("Negative numbers are not supported.");

  if (num === 0) return "zero";

  //the case of 1 - 20
  if (num < 20) {
    return ones[num];
  }

  if (numString.length === 2) {
    return tens[numString[0]] + " " + ones[numString[1]];
  }

  //100 and more
  if (numString.length == 3) {
    if (numString[1] === "0" && numString[2] === "0")
      return ones[numString[0]] + " hundred";
    else
      return (
        ones[numString[0]] +
        " hundred and " +
        inWords(+(numString[1] + numString[2]))
      );
  }

  if (numString.length === 4) {
    var end = +(numString[1] + numString[2] + numString[3]);
    if (end === 0) return ones[numString[0]] + " thousand";
    if (end < 100) return ones[numString[0]] + " thousand and " + inWords(end);
    return ones[numString[0]] + " thousand " + inWords(end);
  }
}

const SERVICE = {
  processResponse: processResponse,
  getUser: getUser,
  setUser: setUser,
  setLocation: setLocation,
  getLocation: getLocation,
  uploader: s3,
  getImages: getImages,
  getShortAddress: getShortAddress,
  getLongAddress: getLongAddress,
  groupBy: groupBy,
  groupByPropertyId: groupByPropertyId,
  groupByInvoiceType: groupByInvoiceType,
  groupByDueDate: groupByDueDate,
  inWords: inWords,
  checkLastUpdate: checkLastUpdate,
};
export default SERVICE;
