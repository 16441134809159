import { action, computed, persist } from "easy-peasy";

const imageModel = persist({
  item:[0, 1, 2, 3, 4, 5],
  isFound: computed((state) => state.item != null),
  save: action((state, payload) => {
    state.item = payload;
  }),
  add: action((state, payload) => {
    state.item.push(payload);
  }),
  addById: action((state, payload) => {
    state.item[payload.index] = payload.data;
  }),
  deleteById: action((state, payload) => {
    // state.item.splice(state.item.findIndex((item) => item.index == payload.index), 1);
    // state.item= state.item.filter(list=> list.id !== payload.data);
    state.item.splice((payload.index), 1);
    state.item.push(1);
  }),
  reset: action((state, payload) => {
    state.item = [0, 1, 2, 3, 4, 5];
  }),
});
export default imageModel;
