import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: "1rem",
  },
}));

export default function PageHeader(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.heading}>
        <Typography gutterBottom variant="h6">
          {props.title}
        </Typography>
      </div>
    </>
  );
}
