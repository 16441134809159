import { action, computed, persist } from "easy-peasy";

const locationModel = persist({
  position: null,
  setPlace:null,
  searchLocation: null,
  searchEnter:null,
  setSearchloading:false,
  //  proploading:null,
  setSearchLocation: action((state, payload) => {
    const pay = JSON.stringify(payload);
    state.searchLocation = JSON.parse(pay);
  }),
  latLng: computed((state) => {
    return state.position?.geometry?.location;
  }),
  //   formatedAddress: computed((state) => {
  //     return state.position.formatted_address;
  //   }),
  setLocation: action((state, payload) => {
    state.position = payload;
  }),
  setPlace: action((state, payload) => {
    state.place = payload;
  }),

  setProploading: action((state, payload) => {
    state.proploading = payload;
  }),
  setSearchloading: action((state, payload) => {
    state.searchloading = payload;
  }),
  setSearchEnter: action((state, payload) => {
    state.searchEnter = payload;
  }),
  unsetLocation: action((state, payload) => {
    state.position = null;
  }),
  getFormattedAddress: computed((state) => {
    // Note how we are returning a function instead of state
    //      👇
    return () => state.position?.formatted_address || " ";
  }),
});
export default locationModel;
