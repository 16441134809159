import { action, computed, persist } from "easy-peasy";

const filterModel = {
  list: null,
  saveFilter:null,
  isFound: computed((state) => state.list != null),
  save: action((state, payload) => {
    state.list = payload;
  }),
  add: action((state, payload) => {
    state.list.push(payload);
  }),
  saveFilter: action((state, payload) => {
    state.listFilter = payload;
  }),
  saveSelected: action((state, payload) => {
    state.clearSelected = payload;
  }),
  deleteByIndex: action((state, payload) => {
    state.list.splice(state.list.findIndex(payload), 1);
  }),
};
export default filterModel;
