import { MuiThemeProvider } from "@material-ui/core";
import { action, createStore, StoreProvider } from "easy-peasy";
import React from "react";
import Geocode from "react-geocode";
import { UserProvider } from "./context/UserContext";
import imageModel from "./models/imageModel";
import locationModel from "./models/locationModel";
import metaModel from "./models/metaModel";
import myPropertyModel from "./models/myPropertyModel";
import profileModel from "./models/profileModel";
import propertyMapModel from "./models/propertyMapModel";
import propertyModel from "./models/propertyModel";
import sessionModel from "./models/sessionModel";
import filterModel from "./models/filterModel";
import Root from "./Root";
import theme from "./theme";
import "./App.css";
import loginModel from "./models/loginModel";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyCe1CIbSG7kwUh7m4G7ZLFGMLT_9984NuU");

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("in");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

const initialState = {};
const storeModel = {
  session: sessionModel,
  login: loginModel,
  location: locationModel,
  property: propertyModel,
  myProperty: myPropertyModel,
  propertyMap: propertyMapModel,
  profile: profileModel,
  image: imageModel,
  meta: metaModel,
  filter: filterModel,
  reset: action((state) => ({
    ...initialState,
  })),
};

const store = createStore(storeModel);

export default function App() {
  return (
    <>
      <StoreProvider store={store}>
        <MuiThemeProvider theme={theme}>
          <UserProvider>
            <Root />
          </UserProvider>
        </MuiThemeProvider>
      </StoreProvider>
    </>
  );
}
