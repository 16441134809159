import { createTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createTheme({
  props: {
    // focused: { outline: "0", border: "0" },
    MuiButton: {
      size: "small",
    },
    MuiFilledInput: {
      margin: "dense",
    },
    MuiFormControl: {
      margin: "dense",
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiListItem: {
      dense: true,
    },
    MuiOutlinedInput: {
      margin: "dense",
    },
    MuiFab: {
      size: "small",
    },
    MuiTable: {
      size: "small",
    },
    MuiTextField: {
      margin: "dense",
      variant: "outlined",
    },
    MuiToolbar: {
      variant: "dense",
    },
    MuiPaper: {
      //variant: "elevation",
      //elevation: 3,
      variant: "outlined",
    },

    textPrimary: {
      color: "#772360",
    },
  },
  palette: {
    focused: { outline: "0", border: "0" },
    primary: {
      main: "#772360",
      //main: "deepPurple",
      contrastText: "#fff",
    },
    secondary: {
      main: "#1d0e1a",
    },
    error: {
      main: "#d62828",
    },
    warning: {
      main: "#fcbf49",
    },
    background: {
      default: "#f3ead4",
    },
  },
  typography: {
    fontFamily: "Poppins",
    color: "#000000",
    fontWeight: "600",
  },
  // MuiInputBase: {
  //   fontSize: "0.8rem",
  //   fontFamily: "Poppins",
  // },
  overrides: {
    // focused: { outline: "0", border: "0" },
    MuiListItem: {
      root: {
        borderBottom:"solid 2px #f0f0f0",
        backgroundColor:"white",
        color:"#000000",
        fontWeight:"500",
        "&$selected": {
          borderBottom: "2px solid #772360",
          backgroundColor:"white",
          color:"#000000",
          "&:hover": {
            backgroundColor: "white",
            borderBottom: "2px solid #772360",
            color:"#000000",
          },
        },
      },
   
    },
    MuiStepLabel: {
      label: {
        fontSize: "px",
      },
    },
    MuiInputBase:{
      input:{
        color:"black",
      },
    },
    MuiInput: {
      underline: {
        "&&:before": {
          borderBottom: "1px solid #ccc ",
        },
        "&&.MuiInput-underline:hover:not(.Mui-disabled):before": {
          borderBottom: "0px transparent",
        },

        "&&:after": {
          borderBottom: "1px solid #ccc",
        },
        "&:hover:not($disabled):before": {
          borderBottom: "1px solid #ccc",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: "2.8rem",
      },
      // MuiFormControl: {
      //   root: {
      //     width: "100%",
      //   },
      // },
      // MuiButton: {
      //   textPrimary: {
      //     color: "#772360",
      //   },
      // },
      // MuiButton: {
      //   outlinedPrimary: {
      //     color: "#772360",
      //   },
      // },
      // MuiButton: {
      //   containedPrimary: {
      //     background: "#772360",
      //     borderRadius: "2.8rem",
      //   },
      // },
      // MuiInputBase: {
      //   input: {
      //     fontSize: "0.8rem",
      //     fontFamily: "Poppins",
      //   },
      //   root: {
      //     fontSize: "0.8rem",
      //     fontFamily: "Poppins",
      //   },
      // },

      // MuiChip: {
      //   root: {
      //     background: "#fff",
      //     border: "1px dashed #cccccc",
      //     backgroundColor: "#fff",
      //   },
      // },
      // MuiBreadcrumbs: {
      //   root: {
      //     paddingBlock: "0.5rem",
      //     paddingInline: "0.5rem",
      //   },
      // },

      // MuiIcon: {
      //   root: {
      //     paddingInline: "0.5rem",
      //   },
      // },
      // MuiIconButton: {
      //   root: {
      //     paddingInline: "0.5rem",
      //   },
      //   sizeSmall: {
      //     // Adjust spacing to reach minimal touch target hitbox
      //     marginLeft: 4,
      //     marginRight: 4,
      //     padding: 12,
      //   },
      // },

      // MuiFab: {
      //   sizeSmall: {
      //     margin: 4,
      //     padding: 12,
      //   },
      // },

      // MuiButtonBase: {
      //   root: {
      //     background: "#fff",
      //     "& .hover": {
      //       background: "#fff",
      //     },
      //   },
      // },
    },
  },
});

theme.overrides.MuiToggleButton = {
  // Override the styling for selected toggle buttons
  root: {
    "&$selected": {
      backgroundColor: "#fff",
      color: theme.palette.primary.main,
    },
  },
};

// theme.overrides.MuiTextField = {
//   outlined:{

//   }
// }

// theme.overrides.MuiInputBase = {
//   root: {
//     "&$focused": {
//       borderColor: theme.palette.primary.light,
//     },
//   },
// };
export default theme;
