import { action, computed } from "easy-peasy";

const propertyModel = {
  item: null,
  isFound: computed((state) => state.item != null),
  save: action((state, payload) => {
    state.item = payload;
  }),
  reset: action((state, payload) => {
    state.item = null;
  }),
  unsetProperty: action((state, payload) => {
    state.item = null;
  }),
  getPropertyTypeById: action((state, id) => {
    return state.item.propertyType?.filter((i) => id == i.id);
  }),
  getpropertyCategoryById: action((state, id) => {
    return state.item.propertyCategory?.filter((i) => id == i.id);
  }),
  getpropertyContactTypeById: action((state, id) => {
    return state.item.propertyContactType?.filter((i) => id == i.id);
  }),
  getBhkTypeById: action((state, id) => {
    return state.item.bhkType?.filter((i) => id == i.id);
  }),
  getFurnishTypeById: action((state, id) => {
    return state.item.furnishType?.filter((i) => id == i.id);
  }),
  getBathRoomById: action((state, id) => {
    return state.item.bathRoomOption?.filter((i) => id == i.id);
  }),
  getParkingById: action((state, id) => {
    return state.item.parkingOption?.filter((i) => id == i.id);
  }),
  getAmenityById: action((state, id) => {
    return state.item.amenity?.filter((i) => id == i.id);
  }),
  getTenantTypeById: action((state, id) => {
    return state.item.tenantType?.filter((i) => id == i.id);
  }),
  getPropertyAgeById: action((state, id) => {
    return state.item.propertyAge?.filter((i) => id == i.id);
  }),
};
export default propertyModel;
