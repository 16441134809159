import { action } from "easy-peasy";

const propertyMapModel = {
  property: null,
  tentant: null,
  bankdetails: null,
  account: null,
  selectedAccounts: null,
  settings: null,
  agreement: null,
  isAgreed: false,
  reset: action((state, payload) => {
    state.property = null;
    state.tenent = null;
    state.bankdetails = null;
    state.account = null;
    state.selectedAccounts = null;
    state.settings = null;
    state.agreement = null;
    state.isAgreed = false;
  }),
  setProperty: action((state, payload) => {
    state.property = payload;
  }),
  setTenant: action((state, payload) => {
    state.tenant = payload;
  }),
  setBankDetails: action((state, payload) => {
    state.bankdetails = payload;
  }),
  setAccount: action((state, payload) => {
    state.account = payload;
  }),
  setSelectedAccounts: action((state, payload) => {
    state.selectedAccounts = payload;
  }),
  setSettings: action((state, payload) => {
    state.settings = payload;
  }),
  setAgreement: action((state, payload) => {
    state.agreement = payload;
  }),
  setAgreed: action((state, payload) => {
    state.isAgreed = payload;
  }),
};
export default propertyMapModel;
