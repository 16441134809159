import { action, computed } from "easy-peasy";


const loginModel = {
  mobile: null,
  OpenPayRent: false,
  setMobile: action((state, payload) => {
    state.mobile = payload;
  }),
  setSaveDirect: action((state, payload) => {
    state.saveDirect = payload;
  }),
};
export default loginModel;