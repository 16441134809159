import {
  Chip,
  createTheme,
  Grid,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingBottom: "0.5rem",
    marginLeft: "-0.5rem",
    justifyContent: "flex",
    fontFamily: "Poppins",
    fontSize: "0.8rem",
    flexWrap: "wrap",
    color: "#333333",
    "& > *": {
      margin: theme.spacing(0.3, 0.4),
    },
    "& .MuiChip-colorPrimary": {
      background: "#Fff",
      color: "#3fb575",
      border: "1px solid #3fb575",
    },
    "& .makeStyles-listChip": {
      margin: 0,
    },
  },
  chip: {
    marginTop: "0.3rem",
    marginBottom: "0.3rem",
  },
}));
const theme = createTheme({
  overrides: {
    MuiChip: {
      root: {
        color: "#333333",
        border: "1px dashed #cccccc",
        backgroundColor: "#fff",
        padding: "0.5rem",
        margin: "0.5rem",
        "&:focus": {
          background: "#fff",
        },
        "&:active": {
          color: "#3fb575",
          border: "1px solid #3fb575",
          backgroundColor: "#fff",
        },
        "& .MuiChip-colorPrimary": {
          background: "#Fff",
          color: "#3fb575",
          border: "1px solid #3fb575",
        },
      },
      outlined: {
        color: "#3fb575",
        border: "1px solid #3fb575",
        backgroundColor: "#fff",
        "$clickable&:hover, $clickable&:focus, $deletable&:focus": {
          backgroundColor: "#fff",
        },
      },
    },
  },
});
export default function StyledChipGroupObjMultiple(props) {
  const classes = useStyles();

  const [selected, setSelected] = useState(props.options);
  const defaultAmenities = props.value;
  if (defaultAmenities != null && defaultAmenities != undefined) {
    props.options.forEach((option, i) => {
      // let index=0;
      //   defaultAmenities.forEach((parent, i)=>{
      //     const isSame = (option) => props.options =  props.value;
      //     i =  (props.value).findIndex(isSame)
      //     if(i < 0 ){
      //         parent.isActive = true
      //     }
      // });
      props.value.forEach((parent, index) => {
        if (option.id == parent.id) {
          option.isActive = true;
        } else {
        }

      });


    })
  }
  const handleChange = (option, i) => {
    var s = selected;
    s[i].isActive = !s[i].isActive;
    setSelected(s);
    props.onSelect(props.name, s[i]);
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.root}>
            {props.options &&
              props.options.map((option, i) => {
                return (
                  <Chip
                    key={i}
                    className={classes.chip}
                    label={option.value}
                    name={props.name}
                    onClick={() => handleChange(option, i)}
                    variant={
                      selected && selected[i].isActive === true
                        ? "outlined"
                        : "default"
                    }
                  />
                );
              })}
          </div>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
