import React from "react";

const ThreeDots = (props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    }}
  >
    <div className="loader">Loading...</div>
    {/* <ContentLoader
      animate={true}
      viewBox="0 0 400 160"
      height={160}
      width={400}
      speed={1}
      backgroundColor={"#333"}
      foregroundColor={"#999"}
      {...props}
    >
      <circle cx="150" cy="86" r="8" />
      <circle cx="194" cy="86" r="8" />
      <circle cx="238" cy="86" r="8" />
    </ContentLoader> */}
  </div>
);

ThreeDots.metadata = {
  name: "RioF",
  github: "clariokids",
  description: "Three Dots",
  filename: "ThreeDots",
};

export default ThreeDots;
