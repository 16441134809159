const axios = require("axios");
const REMOTE_URL = "https://nextgen.clanizon.com/";
const LOCAL_URL = "http://localhost:8081/";
const API = axios.create({
  baseURL: REMOTE_URL,
  timeout: 60000,
});
API.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export default API;
