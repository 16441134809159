import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import Login from "../pages/Login";

const PrivateRoute = ({ component: Component, userRole, ...rest }) => {
  const userDetails = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        userDetails ? (
          //   <Redirect to={props.location.pathname} />
          <Component {...props} />
        ) : (
          <>
          <Login  direct={props.location.pathname}/>
          {/* <Redirect to="/login" direct={props.location.pathname}/> */}
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
